//import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Canvas } from '@react-three/fiber';
import Spaceship from './components/Spaceship'; // Adjust the import path
import { Environment, OrbitControls } from '@react-three/drei';

function App() {
  return (
    <Canvas style={{ background: 'black', width: '100vw', height: '100vh' }} camera={{ position: [-5, 5, 10]}}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Spaceship />
      <OrbitControls />
      <Environment
        background={false} // Set to true if you want the HDR to be the background of your scene
        files="kiara_1_dawn_1k.hdr" // Path to your HDR file
        path="/" // Path where the hdr image is located, defaults to '/'. Only needed if your files prop is a relative path
      />      
    </Canvas>
  );
}

export default App;
