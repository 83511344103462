// Spaceship.js
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

function Spaceship() {
  const { scene } = useGLTF('/XPR_Racer.glb'); // Adjust the path as needed
  const mesh = useRef();
  useFrame((state, delta) => {
    mesh.current.position.y = Math.sin(state.clock.elapsedTime * 2) * 0.2;
  });

  return <primitive object={scene} ref={mesh} />;
}

useGLTF.preload('/XPR_Racer.glb');

export default Spaceship;
